import { React, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faInstagram,
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import emailjs from '@emailjs/browser';
import "../styles/secondpage.css";
import "../styles/secondpage-responsive.css";

function Secondpage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidePanelRef = useRef(null);
  const hamburgerRef = useRef(null);

  const form = useRef('from_name', 'email');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when clicking outside of the side panel
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="secondpage-container">
      {/* Header */}
      <header className="header">
      <nav className="nav">
          <div className="Nav-logo">
            <a href="Homepage" style={{cursor:"default"}}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/VTLogo.png`}
              alt="VirtualTriage"
              style={{ width: "40vh" }}
            />
            </a>
          </div>
          <div
            className="navbar-hamburger"
            ref={hamburgerRef}
            onClick={toggleMenu}
          >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div
            className={`side-panel ${isMenuOpen ? "open" : ""}`}
            ref={sidePanelRef}
          >
            <div className="nav-links">
              <a href="./Secondpage">
                <button>Practitioners</button>
              </a>
              <button className="button-button-border">How It Works</button>
              <a href="https://portal.virtualtriage.ca/login">
                <button className="button-button-login">Login →</button>
              </a>
            </div>
          </div>
        </nav>
      </header>

      {/* Main Banner */}
      <section className="banner">
        <div className="banner-text">
          <h1>
            Empower Your Health and Wellness Practice with AI-Driven Virtual
            Care.
          </h1>
          <p>
            Offer professional, secure consultations with our AI-powered
            platform. Virtual Triage helps you automate tasks, track
            consultations, reduce admin work, and deliver exceptional care
            anytime, anywhere.
          </p>
          <a href="https://portal.virtualtriage.ca/login"><button className="get-started-btn">Get Started</button></a>
        </div>
        <div className="banner-image">
          <img src="../assets/color3999-1.svg" alt="Mobile UI" />
        </div>
      </section>

      {/* Features Section */}
      <section className="secondpage-features-section">
        <div className="secondpage-features-img">
          <img src="./assets/visuals-1.png" alt="Features" />
        </div>
        <div className="secondpage-features-text">
          <h2>Our Features</h2>
          <p>
            Few good reasons why you should use Virtual Triage as your health
            and wellness assistant.
          </p>
          <div className="secondpage-features">
            <div className="secondpage-feature-item">
              <img src="./assets/human.svg" alt="AI-Powered Symptom Checker" />
              <span>AI-Powered Symptom Checker</span>
            </div>
            <div className="secondpage-feature-item">
              <img
                src="./assets/virtualcall.svg"
                alt="Global Virtual Consultations"
              />
              <span>Global Virtual Consultations</span>
            </div>
            <div className="secondpage-feature-item">
              <img
                src="./assets/calendar.svg"
                alt="Easy Appointment Management"
              />
              <span>Easy Appointment Management</span>
            </div>
            <div className="secondpage-feature-item">
              <img src="./assets/e-payment.svg" alt="Secure E-Payments" />
              <span>Secure E-Payments</span>
            </div>
          </div>
        </div>
      </section>
      {/* Platform Features */}
      <section className="platform-features">
        <h2>The Virtual Triage Platform Features</h2>
        <a href="https://portal.virtualtriage.ca/login"><button className="get-started-btn">Get Started →</button></a>
        <div className="feature-grid">
          <div className="feature-card-1">
            <h3>AI-Powered Precision</h3>
            <p className="feature-card-p">
              Unlike other platforms, Virtual Triage’s AI is built specifically
              for healthcare, offering deeper symptom analysis and more accurate
              practitioner recommendations.
            </p>
          </div>
          <div className="feature-card-2">
            <h3>Streamlined Operations</h3>
            <p className="feature-card-p">
              Automate administrative tasks like appointment scheduling, symptom
              analysis, and client inquiries with our healthcare-trained AI.
            </p>
          </div>
          <div className="feature-card-3">
            <h3>24/7 Availability</h3>
            <p className="feature-card-p">
              Extend care beyond office hours with 24/7 virtual consultations,
              e-prescriptions, and secure payments—boosting client satisfaction
              and retention.
            </p>
          </div>
          <div className="feature-card-4">
            <h3>Cost & Time Savings</h3>
            <p className="feature-card-p">
              By reducing manual tasks and overhead, Virtual Triage saves time
              and costs, making health and wellness more efficient and
              accessible.
            </p>
          </div>
        </div>
      </section>

      {/* Pricing Plans */}
      <section className="pricing-plans">
        <h2>Pick your preferred plan</h2>
        <p>
          Flexible pricing plans designed to fit your needs. Choose the plan
          that works best for you and start delivering quality care today.
        </p>
        <div className="plans">
          <div className="plan-card basic-plan">
            <h3>Basic</h3>
            <p className="price">
              $149<span style={{ fontSize: "18px" }}> / month</span>
            </p>
            <p>Basic Package for single practitioners</p>
            <ul>
              <li>1 Practitioner</li>
              <li>Practitioner Portal</li>
              <li>Unlimited Appointments</li>
              <li>In App messaging</li>
              <li>Video Calling</li>
              <li>Advanced App. Management</li>
              <li>Visibility to patient’s map</li>
              <li>Healthcare trained AI chat bot</li>
            </ul>
            <a href="https://portal.virtualtriage.ca/doctor_register"><button className="pricing-get-started-btn">Get Started</button></a>
          </div>
          <div className="plan-card standard-plan">
            <h3>Standard</h3>
            <p className="price">
              $299<span style={{ fontSize: "18px" }}> / month</span>
            </p>
            <p>Everything in basic plus</p>
            <ul>
              <li>2-5 Practitioners</li>
              <li>Clinic Portal Access</li>
              <li>Advanced Scheduling System</li>
              <li>Appointments Management</li>
              <li>Practitioners Activity</li>
              <li>Revenue Monitoring</li>
              <li>Excel Exportable Data</li>
              <li>Patients Management</li>
            </ul>
            <a href="https://portal.virtualtriage.ca/clinic_register"><button className="pricing-get-started-btn">Get Started</button></a>
          </div>
          <div className="plan-card premium-plan">
            <h3>Premium</h3>
            <p className="premium-price">
              $495<span style={{ fontSize: "18px" }}> / month</span>
            </p>
            <p>Standard but with higher limits</p>
            <ul>
              <li>6-25 Practitioners</li>
              <li>Clinic Portal Access</li>
              <li>Advanced Scheduling System</li>
              <li>Appointments Management</li>
              <li>Practitioners Activity</li>
              <li>Revenue Monitoring</li>
              <li>Excel Exportable Data</li>
              <li>Patients Management</li>
            </ul>
            <a href="https://portal.virtualtriage.ca/clinic_register"><button className="pricing-get-started-btn">Get Started</button></a>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <footer className="contact">
        <div className="contact-section">
          <div className="contact-details">
            <h2>Let's get in touch!</h2>
            <p>
              Got questions about our Virtual Triage App? Our team is here to
              help. Contact us for quick and friendly support.
            </p>
            <div className="contact-info">
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "#0a8586", padding: "0 10px 0 10px" }}
                />{" "}
                +1 (437) 294 6051
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "#0a8586", padding: "0 10px 0 10px" }}
                />{" "}
                teams@virtualtriage.com
              </p>
            </div>
            <h3>Connect with us!</h3>
            <div className="social-icons">
              <a>
                <FontAwesomeIcon
                  icon={faYoutube}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.instagram.com/virtual.triage/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.facebook.com/virtualtriage?mibextid=ZbWKwL">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.linkedin.com/company/virtualtriage/">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://twitter.com/virtual_triage">
                <FontAwesomeIcon
                  icon={faTwitter}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
            </div>
          </div>

          <div className="contact-form-section">
            <h3>Subscribe for Newsletter!</h3>
            <form className="contact-form" ref={form} onSubmit={sendEmail}>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faUser} />
                <input type="text" placeholder="Full Name" name="from_name" />
              </div>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faEnvelope} />
                <input type="email" placeholder="Email" name="email"/>
              </div>
              <button className="submit-button" type="submit" value="Send">
                Submit
              </button>
            </form>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>© 2024 Virtual Triage Healthcare-App</p>
      </div>
    </div>
  );
}

export default Secondpage;
