import { React, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faInstagram,
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import emailjs from '@emailjs/browser';
import "../styles/homepage.css";
import "../styles/homepage-responsive.css";

function Homepage() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidePanelRef = useRef(null);
  const hamburgerRef = useRef(null);

  const form = useRef('from_name', 'email');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
  };

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when clicking outside of the side panel
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const faqItems = [
    {
      title: "How does Virtual Triage help manage my appointments?",
      content:
        "Virtual Triage allows patients and practitioners to seamlessly schedule, manage, and conduct appointments through an easy-to-use interface. The platform supports reminders, rescheduling, and video consultations, ensuring an organized and efficient appointment system.",
    },
    {
      title: "Can I customize my practice's booking options?",
      content:
        "Yes, Virtual Triage offers customization options for practitioners and clinics to set their availability, preferred appointment types (e.g., in-person or virtual), and cancellation policies, making it adaptable to your practice's unique needs.",
    },
    {
      title: "How do I find the right practitioner?",
      content:
        "With Virtual Triage, patients can use the built-in geolocation feature to find practitioners nearby and filter by specialty. You can view practitioner profiles, check ratings, and book appointments directly through the app.",
    },
    {
      title: "Are there any additional charges for video consultations?",
      content:
        "No, there are no hidden fees for using the video consultation feature. The cost of the consultation is determined by the practitioner or clinic, and payments are securely processed through the app using Stripe.",
    },
  ];

  return (
    <div className="homepage">
      {/* Header Section */}
      <header className="header">
        <nav className="nav">
          <div className="Nav-logo">
            <img
              src={`${process.env.PUBLIC_URL}/assets/VTLogo.png`}
              alt="VirtualTriage"
              style={{ width: "40vh" }}
            />
          </div>
          <div
            className="navbar-hamburger"
            ref={hamburgerRef}
            onClick={toggleMenu}
          >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div
            className={`side-panel ${isMenuOpen ? "open" : ""}`}
            ref={sidePanelRef}
          >
            <div className="nav-links">
              <a href="./Secondpage">
                <button>Practitioners</button>
              </a>
              <button className="button-button-border">How It Works</button>
              <a href="https://portal.virtualtriage.ca/login">
                <button className="button-button-login">Login →</button>
              </a>
            </div>
          </div>
        </nav>
        <div className="hero">
          <div className="hero-text">
            <h1>
              Connecting Patients with Health and Wellness Experts - across the
              globe.
            </h1>
            <p>
              Join our platform to book and conduct virtual appointments, get
              e-prescriptions, and manage your healthcare journey with ease.
            </p>
            <div className="hero-buttons">
              <a href="https://portal.virtualtriage.ca/doctor_register">
                <button className="practitioner-user-btn">
                  Practitioner →
                </button>
              </a>
              <a href="https://portal.virtualtriage.ca/register">
                <button className="patient-user-btn">App User →</button>
              </a>
            </div>
          </div>
          <div className="hero-image">
            <img src="./assets/group-7-1.png" alt="HeroImage" />
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="features">
        <h1>The Virtual Triage platform features</h1>
        <div className="feature-cards">
          <div className="feature-card">
            <div className="icon">
              <img src="./assets/human.svg" alt="AI-Powered Symptom Analysis" />
            </div>
            <h3>AI-Powered Symptom Analysis</h3>
            <p>
              An AI chatbot matches clients with the right expert, offering
              practitioners upfront insights.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/e-payment.svg"
                alt="E-Payments & E-Prescriptions"
              />
            </div>
            <h3>E-Payments & E-Prescriptions</h3>
            <p>
              Streamlined, secure transactions and prescription handling benefit
              both practitioners and clients.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/calendar.svg"
                alt="E-Payments & E-Prescriptions"
              />
            </div>
            <h3>Appointment Booking/Management</h3>
            <p>
              Easy booking and automated reminders reduce no-shows for
              practitioners while offering clients flexible scheduling.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/icon-3.svg"
                alt="E-Payments & E-Prescriptions"
                style={{ scale: "0.7" }}
              />
            </div>
            <h3>Personalized Care Plans</h3>
            <p>
              Practitioners can create tailored treatment plans, while clients
              track their progress seamlessly.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/virtualcall.svg"
                alt="E-Payments & E-Prescriptions"
              />
            </div>
            <h3>Real-Time Video & Messaging</h3>
            <p>
              Connect through high-quality video calls and messaging, enabling
              care anytime, anywhere.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/icon-5.svg"
                alt="E-Payments & E-Prescriptions"
                style={{ scale: "0.7" }}
              />
            </div>
            <h3>Marketplace for Health & Wellness</h3>
            <p>
              A platform where individuals find experts and practitioners
              showcase their services, enhancing connections and accessibility.
            </p>
          </div>
        </div>
      </section>

      {/* Specialties Section */}
      <section className="specialties">
        <h1>Our platform supports a wide range of specialties</h1>
        <p>
          Whether you're seeking care or offering services, Virtual Triage
          connects experts and clients seamlessly, all in one place.
        </p>
        <div className="specialty-list">
          <button>
            Dermatology
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Sleep Therapy
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Wellness Coaching
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Psychology
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Marriage Therapy
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Nutrition
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Speech Therapy
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
          <button>
            Mental Health
            <img src="./assets/arrowright-60.svg" alt="specialty-arrow" />
          </button>
        </div>
      </section>

      {/* Practitioners & Individuals Sections */}
      <section className="practitioners-individuals">
        <div className="practitioners">
          <div className="for-practitioners-image">
            <img src="./assets/for practitioners.png" alt="Practitioners" />
          </div>
          <div className="for-practitioner-text">
            <h1 className="roles-sections">PRACTITIONERS:</h1>
            <h2>Automate administrative tasks, and optimize your services</h2>
            <p>
              <strong>Increase Revenue with Virtual Consultations: </strong>
              Offer remote consultations to reach more clients without
              geographic constraints.
            </p>
            <p>
              <strong>Enhance Client Retention: </strong>Personalized care plans
              and follow-up reminders keep clients engaged, fostering long-term
              relationships.
            </p>
            <p>
              <strong>Data-Driven Insights: </strong>Access analytics on
              appointment trends and outcomes to refine your services and
              improve your practice.
            </p>
            <a href="https://portal.virtualtriage.ca/doctor_register">
              <button className="submit-button">Get Started →</button>
            </a>
          </div>
        </div>
        <div className="individuals">
          <div className="for-individuals-image">
            <img src="./assets/for individuals.png" alt="Individuals" />
          </div>
          <div className="for-individuals-text">
            <h1 className="roles-sections">INDIVIDUALS:</h1>
            <h2>Book virtual appointments and access care instantly</h2>
            <p>
              <strong>Access a Range of Specialists: </strong>Easily connect
              with health and wellness experts across various fields, all in one
              platform.
            </p>
            <p>
              <strong>24/7 Availability: </strong>Book, message, or review
              treatment plans anytime, for full flexibility in managing your
              health.
            </p>
            <p>
              <strong>Cost-Effective Care: </strong>Save time and money with
              virtual consultations, offering affordable and convenient care.
            </p>
            <a href="https://portal.virtualtriage.ca/register">
              <button className="submit-button">Get Started →</button>
            </a>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h1>Hear from our satisfied customers</h1>
        <p>
          See how our app is making impact for practitioners and individuals.
        </p>
        <div className="testimonial-cards">
          <div className="testimonial-card">
            <img src="./assets/robert brice.png" alt="Robert Bryce" />
            <h4>Dr. Robert Bryce</h4>
            <p style={{ color: "#50cada" }}>Physiotherapist @ Peak Physio</p>
            <p>
              <strong>
                "This App has revolutionized my healthcare experience. The ease
                of scheduling appointments and the efficiency of video
                consultations have given me the flexibility to prioritize my
                health without any hassle."
              </strong>
            </p>
            <img src="./assets/StarIcon49.png" alt="Ratings" />
          </div>
          <div className="testimonial-card">
            <img src="./assets/boateng.png" alt="Terence Boateng" />
            <h4>Terence Boateng</h4>
            <p style={{ color: "#50cada" }}>
              Registered Dietitian @ CS Nutrition
            </p>
            <p>
              <strong>
                "Virtual Triage has been a game changer for me! The convenience
                of booking appointments and connecting with healthcare
                professionals through video consultations has made my health
                journey a breeze."
              </strong>
            </p>
            <img src="./assets/StarIcon49.png" alt="Ratings" />
          </div>
          <div className="testimonial-card">
            <img src="./assets/rakesh-modi.jpg" alt="Rakesh Modi" />
            <h4>Rakesh Modi</h4>
            <p style={{ color: "#50cada" }}>User of Virtual Triage App</p>
            <p>
              <strong>
                "Virtual Triage has become an essential part of my health care
                routine. The convenience of scheduling appointments and having
                video consultations has made managing my health so much easier.
                Thank you for this amazing service!"
              </strong>
            </p>
            <img src="./assets/StarIcon49.png" alt="Ratings" />
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Questions & Answers</h2>
        <div className="faq-list">
          {faqItems.map((item, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFaq(index)}>
                {item.title}
                <span className="faq-icon">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Download Section */}
      <section className="download">
        <div className="download-img-area">
          <img
            src="./assets/visuals-5.svg"
            alt="Download Virtual Triage App NOW"
          />
        </div>
        <div className="download-text-area">
          <h1>Download the Ultimate Health & Wellness Marketplace!</h1>
          <p>
            Whether you're a practitioner expanding your reach or seeking
            personalized care, Virtual Triage connects you to endless
            possibilities. Find the right expert or showcase your services on
            our AI-powered platform. <strong>Start today!</strong>
          </p>
          <div className="download-links">
            <a
              href="https://play.google.com/store/apps/details?id=com.earcanada&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./assets/playstore.png" alt="Play Store" />
            </a>
            <a
              href="https://apps.apple.com/pk/app/virtual-triage/id1644720170"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./assets/appstore.png" alt="AppStore" />
            </a>
          </div>
          <a href="https://portal.virtualtriage.ca/login">
            <button className="submit-button">Get Started →</button>
          </a>
        </div>
      </section>

      {/* Contact Section */}
      <footer className="contact">
        <div className="contact-section">
          <div className="contact-details">
            <h2>Let's get in touch!</h2>
            <p>
              Got questions about our Virtual Triage App? Our team is here to
              help. Contact us for quick and friendly support.
            </p>
            <div className="contact-info">
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "#0a8586", padding: "0 10px 0 10px" }}
                />{" "}
                +1 (437) 294 6051
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "#0a8586", padding: "0 10px 0 10px" }}
                />{" "}
                teams@virtualtriage.com
              </p>
            </div>
            <h3>Connect with us!</h3>
            <div className="social-icons">
              <a>
                <FontAwesomeIcon
                  icon={faYoutube}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.instagram.com/virtual.triage/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.facebook.com/virtualtriage?mibextid=ZbWKwL">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.linkedin.com/company/virtualtriage/">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://twitter.com/virtual_triage">
                <FontAwesomeIcon
                  icon={faTwitter}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
            </div>
          </div>

          <div className="contact-form-section">
            <h3>Subscribe for Newsletter!</h3>
            <form className="contact-form" ref={form} onSubmit={sendEmail}>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faUser} />
                <input type="text" placeholder="Full Name" name="from_name" />
              </div>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faEnvelope} />
                <input type="email" placeholder="Email" name="email"/>
              </div>
              <button className="submit-button" type="submit" value="Send">
                Submit
              </button>
            </form>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>© 2024 Virtual Triage Healthcare-App</p>
      </div>
    </div>
  );
}

export default Homepage;
